import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDataTable } from "../../../../hooks/useDataTable";
import DataTableCardOffer from "../../components/DataTableCardOffer";
import {
  createCardOffer,
  deleteCardOffer,
  getAllCardsOffer,
} from "../../../../services/index/getAllPackage";
import axios from "axios";
import { toast } from "react-hot-toast";

const ManagePackage = () => {
  const [packages, setPackages] = useState([]);
  const {
    userState,
    currentPage,
    searchKeyword,
    data: postsData,
    isLoading,
    isFetching,
    isLoadingDeleteData,
    queryClient,
    searchKeywordHandler,
    submitSearchKeywordHandler,
    deleteDataHandler,
    setCurrentPage,
  } = useDataTable({
    dataQueryFn: () => getAllCardsOffer(searchKeyword, currentPage),
    dataQueryKey: "packages",
    deleteDataMessage: "Package is deleted",
    mutateDeleteFn: ({ slug, token }) => {
      return deleteCardOffer({
        slug,
        token,
      });
    },
  });

  const addNewPackage = async () => {
    const newCardOffer = {
      premium: false,
      name: "Test Name",
      description: "Test Desc",
      price: "19.5 USD Test Price",
      points: [{ text: "Test Point included", isAvailable: true }],
    };

    try {
      const newPackage = await createCardOffer({
        token: userState.userInfo.token,
        cardData: newCardOffer,
      });
      setPackages([...packages, newPackage]);
      toast.success("New package added successfully");
      queryClient.invalidateQueries("packages");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <button
        onClick={addNewPackage}
        className="mb-4 rounded bg-blue-500 px-4 py-2 text-white"
      >
        New Package
      </button>
      <DataTableCardOffer
        pageTitle="Manage packages"
        dataListName="Package"
        searchInputPlaceHolder="packages title..."
        searchKeywordOnSubmitHandler={submitSearchKeywordHandler}
        searchKeywordOnChangeHandler={searchKeywordHandler}
        searchKeyword={searchKeyword}
        tableHeaderTitleList={[
          "Premium",
          "Name",
          "Description",
          "Price",
          "Actions",
        ]}
        isLoading={isLoading}
        isFetching={isFetching}
        data={postsData?.data}
        pagination={postsData?.pagination}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        userState={userState}
      >
        {postsData?.data?.map((packageItem) => (
          <tr key={packageItem._id}>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={packageItem.premium}
                  disabled
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
              </div>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <div className="flex items-center">
                <div className="ml-3">
                  <p className="whitespace-no-wrap text-gray-900">
                    {packageItem.name}
                  </p>
                </div>
              </div>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <div className="flex items-center">
                <div className="ml-3">
                  <p className="whitespace-no-wrap text-gray-900">
                    {packageItem.description}
                  </p>
                </div>
              </div>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <div className="flex items-center">
                <div className="ml-3">
                  <p className="whitespace-no-wrap text-gray-900">
                    {packageItem.price}
                  </p>
                </div>
              </div>
            </td>
            <td className="space-x-5 border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <button
                disabled={isLoadingDeleteData}
                type="button"
                className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed disabled:opacity-70"
                onClick={() => {
                  console.log("Package ID to delete:", packageItem._id);
                  deleteDataHandler({
                    slug: packageItem?._id,
                    token: userState.userInfo.token,
                  });
                }}
              >
                Delete
              </button>
              <Link
                to={`/admin/package/manage/edit/${packageItem._id}`}
                className="text-green-600 hover:text-green-900"
              >
                Edit
              </Link>
            </td>
          </tr>
        ))}
      </DataTableCardOffer>
    </div>
  );
};

export default ManagePackage;
