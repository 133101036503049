import React from "react";
import { AiFillYoutube, AiFillInstagram } from "react-icons/ai";
import { FaTiktok, FaFacebook, FaLinkedin } from "react-icons/fa";

import { images } from "../constants";

const Footer = () => {
  return (
    <section className="bg-dark-hard">
      <footer className="container mx-auto grid grid-cols-10 gap-y-10 gap-x-5 px-5 py-10 md:grid-cols-12 md:pt-20 lg:grid-cols-10 lg:gap-x-10">
        <div className="col-span-5 md:col-span-4 lg:col-span-2">
          <h3 className="font-bold text-dark-light md:text-lg">Produktet</h3>
          <ul className="mt-5 space-y-4 text-sm text-[#959EAD] md:text-base">
            <li>
              <a href="https://paridsmartsolution.al/#paketat">PC + monitor</a>
            </li>
            <li>
              <a href="https://paridsmartsolution.al/#paketat">
                Printer termik 80 mm
              </a>
            </li>
            <li>
              <a href="https://paridsmartsolution.al/#paketat">Palmar</a>
            </li>
            <li>
              <a href="https://paridsmartsolution.al/#paketat">
                Pos all in one
              </a>
            </li>
            <li>
              <a href="https://paridsmartsolution.al/#paketat">
                Skanues barkodi/wifi
              </a>
            </li>
            <li>
              <a href="https://paridsmartsolution.al/#paketat">
                Peshore elektronike
              </a>
            </li>

            <li>
              <a href="https://paridsmartsolution.al/#paketat">Paisje smart</a>
            </li>
            <li>
              <a href="https://paridsmartsolution.al/#paketat">
                Printer canon A4
              </a>
            </li>
            <li>
              <a href="https://paridsmartsolution.al/#paketat">
                Peshore elektronike
              </a>
            </li>
          </ul>
        </div>
        <div className="col-span-5 md:col-span-4 lg:col-span-2">
          <h3 className="font-bold text-dark-light md:text-lg">Shëbimet</h3>
          <ul className="mt-5 space-y-4 text-sm text-[#959EAD] md:text-base">
            <li>
              <a href="https://paridconsulting.al/">Program Manaxherial</a>
            </li>

            <li>
              <a href="https://paridsmartsolution.al/#ofertat">
                Program Fiskalizimi
              </a>
            </li>
            <li>
              <a href="https://paridsmartsolution.al/#ofertat">
                Konsulenca Biznesi
              </a>
            </li>
            <li>
              <a href="https://blog.pss.al/contact">Suport IT </a>
            </li>
          </ul>
        </div>
        <div className="col-span-5 md:col-span-4 md:col-start-5 lg:col-span-2 lg:col-start-auto">
          <h3 className="font-bold text-dark-light md:text-lg">Kompania</h3>
          <ul className="mt-5 space-y-4 text-sm text-[#959EAD] md:text-base">
            <li>
              <a href="/about">About</a>
            </li>
            {/* <li>
              <a href="/contact">Kontaktoni</a>
            </li> */}
          </ul>
        </div>
        <div className="col-span-5 md:col-span-4 lg:col-span-2">
          <h3 className="font-bold text-dark-light md:text-lg">Location</h3>
          <ul className="mt-5 space-y-4 text-sm text-[#959EAD] md:text-base">
            <li>
              <a href="https://maps.app.goo.gl/qxUEmwA51K3fJft88">Tirane</a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/Rruga+Lefter+Talo+50,+Sarand%C3%AB,+Albania/@39.8745354,20.0109805,19z/data=!4m6!3m5!1s0x135b14f7a8f23d53:0xcd30cc4a6752e1e4!8m2!3d39.874522!4d20.011584!16s%2Fg%2F11t4y6c0lh?hl=en&entry=ttu">
                Sarande
              </a>
            </li>
            <li>
              <a>Korce</a>
            </li>
          </ul>
        </div>
        <div className="col-span-10 md:order-first md:col-span-4 lg:col-span-2">
          <img
            src={images.LogoWhiteParid}
            alt="logo"
            className="mx-auto brightness-0 invert md:mx-0"
          />
          <p className="mt-4 text-center text-sm text-dark-light md:text-left md:text-base lg:text-sm">
            Mund të na ndiqni gjithashtu ne rrjetet sociale
          </p>
          <ul className="mt-5 flex items-center justify-center space-x-4 text-gray-300 md:justify-start">
            <li>
              <a href="https://www.tiktok.com/@paridsmartsolution?is_from_webapp=1&sender_device=pc">
                <FaTiktok className="h-auto w-6" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@paridsmartsolution4941">
                <AiFillYoutube className="h-auto w-6" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/paridsmartsolution/">
                <AiFillInstagram className="h-auto w-6" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100082817697512">
                <FaFacebook className="h-auto w-6" />
              </a>
            </li>
            <li>
              <a href="https://al.linkedin.com/company/parid-smart-solution?trk=public_post_feed-actor-name">
                <FaLinkedin className="h-auto w-6" />
              </a>
            </li>
          </ul>
        </div>
        <div className="hidden flex-col items-center space-y-4 md:col-span-12 md:flex lg:col-span-10">
          <div className="rounded-full bg-white p-3 text-white">
            <img
              className="h-12 w-12"
              src={images.LogoParidIconSmall}
              alt="logo"
            />
          </div>
          <p className="font-bold italic text-dark-light">
            ©2024 ParidSmartSolution, Inc. All rights reserved. •Terms of use
            •Privacy Policy
          </p>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
