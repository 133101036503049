import React from "react";
import "./ContactCard.css"; // You can add custom styles here

const ContactCard = () => {
  return (
    <div className="flex">
      <div className="contact-list">
        <div className="first_div_support list_supp flex-col">
          <h3>SUPPORT</h3>
          <p>
            <a href="mailto:support@paridsmartsolution.al">
              support@paridsmartsolution.al
            </a>
          </p>
        </div>

        <div className="second_div_shitje list_supp flex-col">
          <h3>SHITJE</h3>
          <p>
            <a href="mailto:salesdepartament@paridsmartsolution.al">
              salesdepartament@paridsmartsolution.al
            </a>
          </p>
        </div>

        <div className="third_div_informacion list_supp flex-col">
          <h3>INFORMACION</h3>
          <p>
            <a href="mailto:info@paridsmartsolution.al">
              info@paridsmartsolution.al
            </a>
          </p>
        </div>
      </div>

      {/* <div className="contact-card">
        <h2>Kontakt</h2>

        <p>
          Në qoftë se dëshironi të pyesni ose mund të keni nevojë për shërbimin
          që ne ofrojmë, ju lutemi të na kontaktoni.
          <br />
          Ne jemi këtu për t'ju ndihmuar!
        </p>
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Emer Mbiemer</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mesazhi</label>
            <textarea id="message" name="message" rows="4" required></textarea>
          </div>
          <button type="submit" className="button_contact_send">
            Dërgo
          </button>
        </form>
      </div> */}
    </div>
  );
};

export default ContactCard;
