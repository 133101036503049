import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const TitleComponent = () => {
  const userState = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const response = await axios.get("https://blog.pss.al:8000/api/title");
        if (response.data) {
          setTitle(response.data.title);
          setSubtitle(response.data.subtitle);
        }
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    fetchTitle();
  }, []);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubtitleChange = (e) => {
    setSubtitle(e.target.value);
  };

  const handleSave = async () => {
    try {
      const token = userState.userInfo.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(
        "https://blog.pss.al:8000/api/title",
        {
          title,
          subtitle,
        },
        config
      );
      console.log(response.data.message);
    } catch (error) {
      console.error("There was an error saving the data!", error);
    }
  };
  return (
    <div>
      <div className="flex-col">
        <div>TitleComponent</div>
        <textarea
          className="d-input-bordered d-input border-slate-300 font-roboto text-xl font-medium text-dark-hard !outline-slate-300"
          style={{ height: "100px", width: "400px" }}
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter Title"
        />
      </div>
      <div className="flex-col">
        <div>SubTitle</div>
        <textarea
          className="d-input-bordered d-input border-slate-300 font-roboto text-xl font-medium text-dark-hard !outline-slate-300"
          style={{ height: "100px", width: "400px" }}
          value={subtitle}
          onChange={handleSubtitleChange}
          placeholder="Enter Subtitle"
        />
      </div>
      <button
        className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default TitleComponent;
