import Logo from "../assets/Logo.svg";
// import LogoParid from "../assets/LogoParid.svg";
import LogoParidIcon from "../assets/LogoParidIcon.svg";
import LogoParidIconSmall from "../assets/LogoParidIconSmall.png";
import LogoAdmin from "../assets/LogoAdmin.png";
import LogoWhiteParid from "../assets/LogoWhiteParid.svg";
// import HeroImage from "../assets/HeroImage.svg";
import HeroImage from "../assets/HeroImage.png";
import Post1Image from "../assets/posts/post1.jpg";
import PostProfileImage from "../assets/posts/post-profile.svg";
import CtaImage from "../assets/CtaImage.jpg";
const samplePostImage = "/images/sample.jpg";
const userImage = "/images/user.png";

const images = {
  Logo,
  // LogoParid,
  LogoAdmin,
  LogoWhiteParid,
  LogoParidIcon,
  LogoParidIconSmall,
  HeroImage,
  Post1Image,
  PostProfileImage,
  CtaImage,
  samplePostImage,
  userImage,
};

export default images;
