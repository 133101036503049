import React from "react";
import companyLogo from "../../assets/LogoParid.svg"; // Update this path to your company's logo
import "./HeaderAbout.css";

const HeaderAbout = () => {
  return (
    <header className="headerAbout_div  bg-gray-200 p-5">
      <h1 className="text-xl font-bold">About Us</h1>
      <img
        src={companyLogo}
        alt="Company Logo"
        className="h-12 w-auto"
      />
    </header>
  );
};

export default HeaderAbout;
