import React, { useState, useEffect } from "react";
import { images } from "../../../constants";
import Search from "../../../components/Search";
import axios from "axios";

const Hero = ({handleSearchKeyword}) => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const response = await axios.get(`https://blog.pss.al:8000/api/title`);
        if (response.data) {
          setTitle(response.data.title);
          setSubtitle(response.data.subtitle);
        }
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    fetchTitle();
  }, []);

 
  return (
    <section className="container mx-auto flex flex-col px-5 py-5 lg:flex-row">
      <div className="mt-10 lg:w-1/2">
        <h1 className="text-center font-roboto text-3xl font-bold text-dark-soft md:text-5xl lg:max-w-[540px] lg:text-left lg:text-4xl xl:text-5xl">
          {title || "Loading..."}
        </h1>
        <p className="mt-4 text-center text-dark-light md:text-xl lg:text-left lg:text-base xl:text-xl">
          {subtitle || "Loading..."}
        </p>
        <Search className="mt-10 lg:mt-6 xl:mt-10" onSearchKeyword={handleSearchKeyword} />
        <div className="mt-4 flex flex-col lg:mt-7 lg:flex-row lg:flex-nowrap lg:items-start lg:gap-x-4">
          <span className="mt-2 font-semibold italic text-dark-light lg:mt-4 lg:text-sm xl:text-base">
            Popular Tags:
          </span>
          <ul className="mt-3 flex flex-wrap gap-x-2.5 gap-y-2.5 lg:text-sm xl:text-base">
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 font-semibold text-primary">
              #Parid Smart Solution
            </li>
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 font-semibold text-primary">
              #Fiskalizim
            </li>
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 font-semibold text-primary">
              #Program Fiskalizimi
            </li>
          </ul>
        </div>
      </div>
      <div className="lg:w-1/2 hidden lg:block">
        <img
          className="w-full"
          src={images.HeroImage}
          alt="users are reading articles"
        />
      </div>
    </section>
  );
};

export default Hero;
