import React from "react";

import MainLayout from "../../components/MainLayout";
import HeaderAbout from "./HeaderAbout";
import About from "./About";
import CTA from "../home/container/CTA";

const AboutPage = () => {
  return (
    <MainLayout>
      <HeaderAbout/>
      <About />
      <CTA />
    </MainLayout>
  );
};

export default AboutPage;
