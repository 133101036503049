import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutPage = () => {
  const [expanded, setExpanded] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);

  // Additional content in Albanian
  const additionalContent = `
    Parid Smart Solution është një lider në tregun e zgjidhjeve të avancuara për menaxhimin e depove, faturimin dhe fiscalizimin në Shqipëri. Me një përvojë më shumë se 8 vjet, ne kemi shërbyer me sukses një bazë të madhe klientësh, duke fituar besimin dhe respektin e tyre. Në fushën e menaxhimit të faturave dhe dokumentacionit fiskal, jemi një zgjidhje e preferuar për bizneset e ndryshme, duke përfshirë restorantet, dyqanet dhe bizneset e mëdha në të gjithë vendin.

    Eksperienca jonë e gjatë dhe ekspertiza në fushën e teknologjisë na ka lejuar të zhvillojmë një gamë të gjerë programesh dhe zgjidhjesh të personalizuara, të cilat janë të përshtatshme për nevojat e secilit klient. Ne ofrojmë një platformë të integrimit të përsosur dhe përvoja të përdorueshme në të gjitha format e aplikacioneve, përfshirë iOS, Android dhe desktop.

    Në Parid Smart Solution, jemi të përkushtuar për të siguruar shërbime të shkëlqyera dhe për të mbështetur klientët në çdo hap të rrugës. Ekipi ynë i kualifikuar ofron këshillim personalizuar dhe asistencë teknike, duke ndihmuar bizneset të përmirësojnë efikasitetin dhe të arrijnë objektivat e tyre zhvillimore.

    Për më shumë informacion rreth zgjidhjeve tona dhe përvojës sonë në tregun vendas, ju ftojmë të vizitoni faqen tonë zyrtare dhe të shfletoni artikujt dhe rastet studimore që tregojnë suksesin tonë në përmirësimin e operacioneve të biznesit. Na kontaktoni sot për të diskutuar opsionet e disponueshme dhe për të filluar një bashkëpunim të suksesshëm!
  `;

  const seoContent = `
    Parid Smart Solution është një lider në tregun e zgjidhjeve të avancuara për menaxhimin e depove, faturimin dhe fiscalizimin në Shqipëri. Për më shumë se 8 vjet, ne kemi ofruar shërbime të shkëlqyera dhe kemi fituar besimin e një bazë të madhe klientësh. Eksperienca jonë e gjatë dhe ekspertiza në teknologji na ka lejuar të zhvillojmë programe dhe zgjidhje të personalizuara për biznese të ndryshme në tregun vendas.

    Parid Smart Solution ofron zgjidhje inovative për menaxhimin e dokumentacionit fiskal dhe të faturave në industri të ndryshme, duke përfshirë restorantet dhe bizneset e mëdha. Ne garantojmë një platformë të integrimit të përsosur dhe mbështetje të plotë në platformat iOS, Android dhe desktop.

    Ekipi ynë i përkushtuar ofron këshillim personalizuar dhe asistencë teknike për të ndihmuar bizneset të përmirësojnë efikasitetin dhe të arrijnë sukses në operacionet e tyre. Vizitoni faqen tonë zyrtare për të mësuar më shumë rreth zgjidhjeve tona dhe përvojës sonë në industrinë e teknologjisë dhe menaxhimit të biznesit në Shqipëri.
  `;

  const corporateResponsibility = `
    Parid Smart Solution e konsideron përgjegjësinë korporative si thelbësore për operacionet tona. Ne vlerësojmë impaktin tonë në mjedis dhe komunitet, dhe kemi ndërmarrë hapa konkrete për të përmirësuar qëndrueshmërinë tonë. Duke ndjekur standarde të larta të përgjegjësisë korporative, ne kemi investuar në teknologji të pastër dhe praktika të cilat mbrojnë mjedisin tonë natyror.

    Në të njëjtën kohë, jemi të përkushtuar në kontributin tonë ndaj komuniteteve ku operojmë. Përmes programit tonë të përgjegjësisë sociale, ne mbështesim iniciativa lokale që promovojnë arsimin, shëndetin dhe zhvillimin ekonomik. Kjo pjesë e ADN-së sonë korporative përcakton identitetin tonë si një partner i besueshëm dhe ndikues në shoqëri.
  `;

  const futureOutlook = `
    Në vizionin tonë për të ardhmen, Parid Smart Solution synon të vazhdojë të inovoje dhe të udhëheqë në tregun e teknologjisë së informacionit dhe menaxhimit të biznesit. Planifikojmë të zgjerojmë praninë tonë në tregje të reja dhe të diversifikojmë ofertën tonë për të përmbushur nevojat e rritshme të klientëve tanë.

    Përmes investimeve në hulumtim dhe zhvillim, ne po zhvillojmë teknologji të reja dhe zgjidhje inovative që do të përmirësojnë qasje dhe efikasitet për klientët tanë. Kështu, ne synojmë të qëndrojmë një hap para konkurrencës dhe të ofrojmë vlerë të vërtetë për bizneset në Shqipëri dhe jashtë saj.
  `;

  const sections = [
    {
      title: "Shitje dhe Blerje",
      description:
        "Parid Smart Solution ofron zgjidhje për menaxhimin e shitjeve dhe blerjeve të biznesit tuaj duke përfshirë monitorimin e transaksioneve, faturimin dhe menaxhimin e klientëve.",
    },
    {
      title: "Inventari",
      description:
        "Sistemet tona për menaxhimin e inventarit ndihmojnë bizneset të ndjekin dhe kontrollojnë mallrat e tyre në mënyrë efektive, duke siguruar që të mos ketë mbiprodhim apo mungesë stoku.",
    },
    {
      title: "Rezervimi",
      description:
        "Zgjidhjet tona për rezervimin ju ndihmojnë të menaxhoni rezervimet në mënyrë efikase, duke ofruar një përvojë të lehtë për klientët dhe stafin tuaj.",
    },
    {
      title: "Arka dhe Banka",
      description:
        "Integrimi i arka dhe bankës në sistemet tona lejon monitorimin e saktë të transaksioneve financiare dhe bilanceve, duke përmirësuar menaxhimin financiar të biznesit tuaj.",
    },
    {
      title: "Kontabiliteti",
      description:
        "Moduli ynë i kontabilitetit ofron një zgjidhje të plotë për menaxhimin e llogarive dhe regjistrave financiarë, duke siguruar një përputhje të plotë me standardet fiskale.",
    },
    {
      title: "Raporte",
      description:
        "Sistemet tona gjenerojnë raporte të detajuara për të gjitha aspektet e biznesit tuaj, duke ju ndihmuar të merrni vendime të informuara dhe strategjike.",
    },
    {
      title: "Qendra kosto",
      description:
        "Parid Smart Solution ofron mjete për menaxhimin e qendrave të kostos, duke ju lejuar të analizoni dhe kontrolloni shpenzimet në nivele të ndryshme të organizatës suaj.",
    },
    {
      title: "Listëpagesa",
      description:
        "Menaxhimi i listëpagesave bëhet i lehtë me zgjidhjet tona, duke siguruar përllogaritjen e saktë dhe të shpejtë të pagave për stafin tuaj.",
    },
    {
      title: "Amortizimi",
      description:
        "Moduli ynë i amortizimit ju ndihmon të menaxhoni dhe të përllogaritni amortizimin e aseteve tuaja, duke siguruar saktësi dhe përputhje me standardet kontabël.",
    },
    {
      title: "Prodhimi",
      description:
        "Zgjidhjet tona për menaxhimin e prodhimit ju ndihmojnë të optimizoni proceset e prodhimit dhe të rrisni efikasitetin në fabrikën tuaj.",
    },
    {
      title: "Administrimi",
      description:
        "Sistemet tona ofrojnë mjete për menaxhimin e administrimit të përgjithshëm të biznesit, duke përfshirë menaxhimin e burimeve njerëzore dhe operacionet e përditshme.",
    },
    {
      title: "Moduli i Aprovimeve",
      description:
        "Moduli ynë i aprovimeve ndihmon në automatizimin dhe menaxhimin e procesit të aprovimit të dokumenteve dhe kërkesave brenda organizatës suaj.",
    },
  ];

  const toggleSection = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  return (
    <section className="container mx-auto px-5 py-10">
      <div className="mx-auto max-w-3xl">
        <h2 className="mb-5 text-3xl font-bold">KUSH JEMI NE?</h2>
        <p className="mb-5 text-lg">
          Parid Smart Solution eshte nje kompani Softwerike e cila operon ne
          treg qe prej vitit 2019. Tanime Parid Smart Solution vijon
          rikonceptimin e sai duke ofruar program kontabiliteti, manaxherial dhe
          fiskal i cili mundson ndjekjen e zhvillimeve te nje aktiviteti deri ne
          detajet me te hollesishme.
        </p>
        <p className="mb-5 text-lg">{expanded ? additionalContent : " "}</p>
        <p className="mb-5 text-lg">
          Parid Smart Solution ofron program Financiar dhe Manaxherial. PSS
          përfshin të gjitha funksionet e një biznesi, administron të dhënat
          financiare, kontabël dhe manaxheriale për ndërmarjet, kompanitë, apo
          entet shtetërore.
        </p>

        {/* {!expanded && (
          <button
            onClick={() => setExpanded(true)}
            className="mb-5 font-bold text-primary"
          >
            Lexo më shumë
          </button>
        )}
        {expanded && (
          <>
            <p className="mb-5 text-lg">{seoContent}</p>
            <p className="mb-5 text-lg">{corporateResponsibility}</p>
            <p className="mb-5 text-lg">{futureOutlook}</p>
          </>
        )}

        {sections.map((section, index) => (
          <div key={index} className="align-center mb-5 mt-5">
            <h3
              onClick={() => toggleSection(index)}
              className="cursor-pointer text-xl font-semibold text-primary hover:underline"
            >
              {section.title}
            </h3>
            {expandedSection === index && (
              <p className="mt-2 text-lg">{section.description}</p>
            )}
          </div>
        ))} */}
      </div>
      {/* <Link
        to="/blog"
        className="mx-auto mt-8 flex items-center gap-x-2 rounded-lg  border-primary px-6 py-3 font-bold text-primary"
      >
        <FaArrowLeft className="h-3 w-3" />
        <span>Shko te Artikujt</span>
      </Link> */}
    </section>
  );
};

export default AboutPage;
