import axios from "axios";

export const getAllCardsOffer = async (
  searchKeyword = "",
  page = 1,
  limit = 10
) => {
  try {
    const { data } = await axios.get(
      `https://blog.pss.al:8000/packages?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`
    );

    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const getCardOfferById = async ({ id }) => {
  try {
    const { data } = await axios.get(`https://blog.pss.al:8000/packages/${id}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const deleteCardOffer = async ({ slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.delete(`https://blog.pss.al:8000/packages/${slug}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const updateCardOffer = async ({ updatedData, id, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.put(`https://blog.pss.al:8000/packages/${id}`, updatedData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const createCardOffer = async ({ token, cardData }) => {
  console.log("Data being sent:", cardData); // Log the data to ensure it's correct
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.post(`https://blog.pss.al:8000/packages`, cardData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};
