import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  getCardOfferById,
  updateCardOffer,
} from "../../../../services/index/getAllPackage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ArticleDetailSkeleton from "../../../articleDetail/components/ArticleDetailSkeleton";
import ErrorMessage from "../../../../components/ErrorMessage";

const EditPackage = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["cardOffer", id],
    queryFn: () => getCardOfferById({ id }),
  });

  const [premium, setPremium] = useState(data?.premium || false);
  const [name, setName] = useState(data?.name || "");
  const [description, setDescription] = useState(data?.description || "");
  const [price, setPrice] = useState(data?.price || "");
  const [points, setPoints] = useState(data?.points || []);

  const token = userState.userInfo.token;

  const mutation = useMutation({
    mutationFn: ({ updatedData, id, token }) => {
      return updateCardOffer({
        updatedData,
        id,
        token,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["cardOffer", id]);
      toast.success("Package is updated");
      navigate(`/admin/package/manage/edit/${data._id}`, { replace: true });
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });

  const handleSave = () => {
    const updatedData = {
      premium,
      name,
      description,
      price,
      points,
    };
    mutation.mutate({ updatedData, id, token });
  };

  useEffect(() => {
    if (data) {
      setPremium(data.premium || false);
      setName(data.name || "");
      setDescription(data.description || "");
      setPrice(data.price || "");
      setPoints(data.points || []);
    }
  }, [data]);

  const addPoint = () => {
    setPoints([...points, { text: "", isAvailable: false }]);
  };

  const removePoint = (index) => {
    setPoints(points.filter((_, i) => i !== index));
  };

  const handlePointChange = (index, field, value) => {
    const updatedPoints = [...points];
    updatedPoints[index] = { ...updatedPoints[index], [field]: value };
    setPoints(updatedPoints);
  };

  if (isLoading) {
    return <ArticleDetailSkeleton />;
  }

  if (isError) {
    return <ErrorMessage message="Couldn't fetch the card offer details" />;
  }

  return (
    <div className="container mx-auto px-5 py-5">
      <section className="mx-auto flex max-w-5xl flex-col">
        <div className="mb-4">
          <div className="d-form-control w-full">
            <label className="d-label" htmlFor="premium">
              <span className="d-label-text">Premium</span>
            </label>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={premium}
                onChange={(e) => setPremium(e.target.checked)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
            </div>
          </div>
        </div>
        <div className="mb-4">
          <div className="d-form-control w-full">
            <label className="d-label" htmlFor="name">
              <span className="d-label-text">Name</span>
            </label>
            <input
              id="name"
              value={name}
              className="d-input-bordered d-input border-slate-300 font-roboto text-xl font-medium text-dark-hard !outline-slate-300"
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name"
            />
          </div>
        </div>
        <div className="mb-4">
          <div className="d-form-control w-full">
            <label className="d-label" htmlFor="description">
              <span className="d-label-text">Description</span>
            </label>
            <input
              id="description"
              value={description}
              className="d-input-bordered d-input border-slate-300 font-roboto text-xl font-medium text-dark-hard !outline-slate-300"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
            />
          </div>
        </div>

        <div className="mb-4">
          <div className="d-form-control w-full">
            <label className="d-label" htmlFor="price">
              <span className="d-label-text">Price</span>
            </label>
            <input
              id="price"
              value={price}
              className="d-input-bordered d-input border-slate-300 font-roboto text-xl font-medium text-dark-hard !outline-slate-300"
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Enter price"
            />
          </div>
        </div>

        <div className="mb-4">
          <strong>Points:</strong>
          {points.map((point, index) => (
            <div key={index} className="mb-2 flex items-center">
              <input
                type="text"
                value={point.text}
                onChange={(e) =>
                  handlePointChange(index, "text", e.target.value)
                }
                placeholder="Point text"
                className="d-input-bordered d-input mr-2 border-slate-300 font-roboto text-xl font-medium text-dark-hard !outline-slate-300"
              />
              <input
                type="checkbox"
                checked={point.isAvailable}
                onChange={(e) =>
                  handlePointChange(index, "isAvailable", e.target.checked)
                }
                className="form-checkbox mr-2 h-5 w-5 text-blue-600"
              />
              <button
                type="button"
                onClick={() => removePoint(index)}
                className="rounded bg-red-500 px-2 py-1 text-white"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addPoint}
            className="mt-2 rounded bg-green-500 px-4 py-2 text-white"
          >
            Add Point
          </button>
        </div>

        <button
          className="mt-4 rounded bg-blue-500 px-4 py-2 text-white"
          onClick={handleSave}
          disabled={mutation.isLoading}
        >
          {mutation.isLoading ? "Saving..." : "Save"}
        </button>
        {mutation.isError && (
          <p className="mt-2 text-red-500">
            Error saving changes. Please try again.
          </p>
        )}
        {mutation.isSuccess && (
          <p className="mt-2 text-green-500">Changes saved successfully!</p>
        )}
      </section>
    </div>
  );
};

export default EditPackage;
