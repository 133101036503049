import React from "react";

import MainLayout from "../../components/MainLayout";
import ContactCard from "./ContactCard";
import CTA from "../home/container/CTA";

const ContactPage = () => {
  return (
    <MainLayout>
      <ContactCard />
      <CTA />
    </MainLayout>
  );
};

export default ContactPage;
